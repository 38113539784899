import React from "react"
import Layout, {DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT} from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import FeaturedRecipes from "../components/Recipe/FeaturedRecipes"
import colors from "../styles/colors"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"

const PageContainer = styled.div`
  padding-top: calc(4vmax + ${DESKTOP_HEADER_HEIGHT}px);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 6.6vmax;

  @media (max-width: ${breakpoints.md}) {
    padding-top: calc(4vmax + ${MOBILE_HEADER_HEIGHT}px);
  }
`

const CategoryTemplate = (props) => {
  const { metaTitle, metaDescription, socialMediaImage } = props.data.contentfulRecipeCategory
  const recipes = props.data.allContentfulRecipePage.edges
  const path = props.location.pathname

  let sharingImage = false
  if (socialMediaImage) {
    sharingImage = socialMediaImage.file.url
  }

  return (
    <Layout bg={colors.royalBlue}>
      <Seo title={metaTitle} description={metaDescription} path={path} image={sharingImage}/>
      <PageContainer>
        <FeaturedRecipes recipes={recipes} />
      </PageContainer>
    </Layout>
  )
}

export default CategoryTemplate

export const pageQuery = graphql`
  query CategoryTemplateQuery($slug: String!) {
    contentfulRecipeCategory(slug: { eq: $slug }) {
      id
      metaTitle
      metaDescription
      slug
      socialMediaImage {
        file {
          url
        }
      }
    }
    allContentfulRecipePage(filter: {category: {slug: { eq: $slug }}}) {
      edges {
        node {
          id
          image {
            gatsbyImageData(width: 1440)
            description
          }
          slug
          category {
            title
            slug
          }
          title
          position
        }
      }
    }
  }
`
